<template>
  <div v-if="Access('DriversView')" class="TopSearchBar color1">
    <input class="color3" v-model="Search" type="text" placeholder="Search...">
  </div>
  <div v-if="CookiesDataDrivers && Access('DriversView')" class="D-Flex" style="padding-top:2em; width:100%; justify-content: center; gap:0.5em; flex-wrap: wrap;">
    <div v-for='(value, index) in FilterdTable' :key='(value, index)' class="CookiesCard Shadow0">
      <div class="CookiesCardInner BG7">
        <div class="D-Flex" style="width: 100%;">
          <span style="padding: 0.3em;" class="M-Auto">{{DeepFetcher(value, ['Employee', 'Name']) ? DeepFetcher(value, ['Employee', 'Name']) : 'N/A'}}</span>
        </div>
        <div class="CardImage" :style="'background: url(' + DefaultImageHandler(DeepFetcher(value, ['Employee', 'ImgUrl']), $store.state.CookiesConfig.UserImage) + ');'">
          <div v-if="DeepFetcher(value, ['Car', 'PlateNum'])" class="CarPlate">{{DeepFetcher(value, ['Car', 'PlateNum'])}}</div>
        </div>
        <div class="D-Flex" style="width: 100%;">
          <span style="padding-top: 0.5em" class="M-Auto">{{DeepFetcher(value, ['Employee', 'Email']) ? DeepFetcher(value, ['Employee', 'Email']) : 'N/A'}}</span>
        </div>
        <div class="D-Flex" style="width: 100%;">
          <span style="padding: 0.2em 0.5em; font-size :0.85em;" class="M-Auto HoverGlow F-Bold" @click="OpenWhatsapp(value['Employee']['PhoneNumber'])">{{DeepFetcher(value, ['Employee', 'PhoneNumber']) ? DeepFetcher(value, ['Employee', 'PhoneNumber']) : 'N/A'}}</span>
        </div>
        <div class="D-Flex" style="width: 100%;">
          <div style="color: rgb(98 172 105);" class="BalanceNum M-Auto F-Bold W-100"><span :class="!DeepFetcher(CashLedger(value['DriverBalance']), ['Debited'] || isNaN(DeepFetcher(CashLedger(value['DriverBalance']), ['Debited']))) ? 'O-25' : ''">+ {{isNaN(DeepFetcher(CashLedger(value['DriverBalance']), ['Debited'])) ? '0' : DeepFetcher(CashLedger(value['DriverBalance']), ['Debited'])}} {{$store.state.CookiesConfig.PriceUnit}}</span></div>
          <div style="color: rgb(191 77 77);" class="BalanceNum M-Auto F-Bold W-100"><span :class="!DeepFetcher(CashLedger(value['DriverBalance']), ['Credited'] || isNaN(DeepFetcher(CashLedger(value['DriverBalance']), ['Credited']))) ? 'O-25' : ''">- {{isNaN(DeepFetcher(CashLedger(value['DriverBalance']), ['Credited'])) ? '0' : DeepFetcher(CashLedger(value['DriverBalance']), ['Credited'])}} {{$store.state.CookiesConfig.PriceUnit}}</span></div>
        </div>
      </div>
      <div class="CardButtons BG8">
        <div class="W-100 H-100"></div>
        <div class="W-100 EvenContainer" style="height: 2.5em; margin-top: -2.75em;">
          <div></div>
          <CookiesIcon :ToolTip="LanguageSelector('Orders', 'الطلبات')" @click="DriverActiveOrders(value)" class="HoverGlow" style="height: 100%; z-index: 50;" :ID="'Reports'"></CookiesIcon>
          <CookiesIcon :ToolTip="LanguageSelector('Balance Account', 'محاسبة')" @click="DriverSettlement(value)" class="HoverGlow" style="height: 100%; z-index: 50;" :ID="'Compensate'"></CookiesIcon>
          <CookiesIcon :ToolTip="LanguageSelector('Returned Items', 'المرتجعات')" @click="DriverReturns(value)" class="HoverGlow" style="height: 100%; z-index: 50;" :ID="'Recover'"></CookiesIcon>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted () {
    this.ReadDrivers()
    this.ReadCars()
  },
  data () {
    return {
      Search: '',
      CookiesDataDrivers: [],
      CookiesDataCars: [],
      CookiesData: []
    }
  },
  methods: {
    ReadCars () {
      this.Get('Cars').then(response => {
        this.CookiesDataCars = response
      }, error => {
        error.CookiesError = 'Error in reading Cars'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    ReadDrivers () {
      this.Get('Employee/getdriver').then(response => {
        this.CookiesDataDrivers = response
      }, error => {
        error.CookiesError = 'Error in reading Drivers'
        this.OpenDialog('ErrorDialog', 'Error', error) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
      })
    },
    DriverSettlement (Driver) {
      this.OpenDialog('DriverBalance', this.LanguageSelector('Driver Account Balance', 'تسكير حساب السائق'), Driver) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    DriverReturns (Driver) {
      this.OpenDialog('DriverReturns', this.LanguageSelector('Driver Returns', 'مرتجعات السائق'), Driver) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    DriverActiveOrders (Driver) {
      this.OpenDialog('DriverOrders', this.LanguageSelector('Driver Active Orders', 'كشف طلبات السائق'), Driver) // [ Dialog Component Name, Dialog Header Text, Dialog Data tot pass ]
    },
    CashLedger (Balance) {
      var CashLedger = {
        Credited: 0,
        Debited: 0
      }
      try {
        var AllLedgers = this.DeepFetcher(Balance, ['ledgers'])
        if (this.ObjectFinder(AllLedgers, ['Type'], 12)) {
          CashLedger = this.ObjectFinder(AllLedgers, ['Type'], 12)
          CashLedger.NetBalance = CashLedger.Debited - CashLedger.Credited
        }
      } catch (error) {
      }
      return CashLedger
    }
  },
  computed: {
    FilterdTable () {
      var FilteredArray = []
      // var SortedWithIDs = this.sortedEmployees.map(function (item) {
      //   item.Hashed_ID = '#' + item.ID
      //   return item
      // })
      this.CookiesDataDrivers.forEach(item => {
        var FilterIt = true
        // if (this.$store.state.CookiesMemory.Employees_Filter_Type !== item.Employee_Type && this.$store.state.CookiesMemory.Employees_Filter_Type !== 'All') {
        //   FilterIt = false
        // }
        // try {
        //   if (this.$store.state.CookiesMemory.Employees_Filter_Page.Page_Name !== item.Employee_Page.Page_Name && this.$store.state.CookiesMemory.Employees_Filter_Page.Page_Name !== 'All') {
        //     FilterIt = false
        //   }
        // } catch (error) {
        // }
        // if (!this.$store.state.CookiesMemory.Employee_Filter_Reserved && item.Employee_Status === 'Reserved') { // ////// Reserved
        //   FilterIt = false
        // }
        // if (!this.$store.state.CookiesMemory.Employee_Filter_Packaging && item.Employee_Status === 'Packaging') { // ////// packaging
        //   FilterIt = false
        // }
        // if (!this.$store.state.CookiesMemory.Employee_Filter_Delivery && item.Employee_Status === 'Delivery') { // ////// Delivery
        //   FilterIt = false
        // }
        // if (!this.$store.state.CookiesMemory.Employee_Filter_Delivered && item.Employee_Status === 'Delivered') { // ////// Delivered
        //   FilterIt = false
        // }
        // if (!this.$store.state.CookiesMemory.Employee_Filter_Fixing && item.Employee_Status === 'NotPaid') { // ////// Fixing
        //   FilterIt = false
        // }
        // if (!this.$store.state.CookiesMemory.Employee_Filter_Done && item.Employee_Status === 'Done') { // ////// Done
        //   FilterIt = false
        // }
        // if (!this.$store.state.CookiesMemory.Employee_Filter_Canceled && item.Employee_Status === 'Canceled') { // ////// Done
        //   FilterIt = false
        // }
        // if (this.$store.state.CookiesMemory.Employee_Filter_PriceHigh && item.Employee_Total_Ammount < this.$store.state.CookiesMemory.Employee_Filter_PriceHigh_Ammount) {
        //   FilterIt = false
        // }
        // if (this.$store.state.CookiesMemory.Employee_Filter_PriceLow && item.Employee_Total_Ammount > this.$store.state.CookiesMemory.Employee_Filter_PriceLow_Ammount) {
        //   FilterIt = false
        // }
        // Search ///////////////////////////////////////////////////////////////////////////////////////////////////
        var SearchIt = this.CookiesSearcher(item, this.Search)
        // /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        if (SearchIt && FilterIt) {
          FilteredArray.push(item)
        }
      })
      return FilteredArray
    },
    CurrentDialogs () {
      return this.$store.getters.CurrentDialogsLength
    }
  },
  watch: {
    CurrentDialogs: function (newVal, oldVal) { // watch it
      if (newVal < oldVal) {
        this.ReadCars()
        this.ReadDrivers()
      }
    }
  }
}
</script>

<style>
/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> Search */
.TopSearchBar{
  width: 100%;
  height: 2em;
}
.CookiesApp{
  overflow-y: scroll;
}
.TopSearchBar > input{
  border-radius: 100px;
  padding: 0.7em 1.5em;
  margin: 0.6em 1em;
  border: none;
  outline: none;
  font-weight: 300;
  font-size: 1em;
  background: v-bind('$store.getters.Theme.Shade1');
}
.TopSearchBar > input::placeholder{
  color: v-bind('$store.getters.Theme.Shade1');
}
/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< Search */
/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> CARD */

.CookiesCard{
  width: 15em;
  height: 19.6em;
  display: inline;
  /* border-radius: var(--rad2); */
  /* overflow: hidden; */
  transition-property: filter, transform;
  transition-duration: 0.2s;
  position: relative;
}
@media (max-width: 250px) {
  .CookiesCard{
    font-size: 5vw;
  }
  .TopSearchBar{
    font-size: 5vw;
  }
}
.CookiesCardInner{
  overflow: hidden;
  border-radius: var(--rad2);
  border: solid 0.01em v-bind('$store.getters.Theme.Shade1');
  height:100%;
  width: 100%;
  user-select: none;
}
.CardButtons{
  height: calc(100%);
  position: absolute;
  width: 100%;
  /* height: 4em; */
  bottom: 0;
  z-index: -55;
  border-radius: var(--rad1);
  transition-property: bottom, opacity, height;
  transition-duration: 0.2s;
  opacity: 0;
}
.CookiesCard:hover{
  transform: scale(1.05);
  z-index: 50;
  filter: drop-shadow(var(--shadow1));
}
.CookiesCard:hover .CardButtons{
  height: calc(100% + 3em);
  bottom: -3em;
  opacity: 1;
}
.CardImage{
  width: 100%;
  height: 12em;
  background-size: cover !important;
  background-position: center !important;
}
.CarPlate{
  background: linear-gradient(45deg, white, rgb(187, 187, 187));
  font-size: 1.2em;
  width: 37%;
  color: rgb(19, 19, 19);
  font-family: fantasy;
  /* font-weight: 900; */
  border-radius: 0.2em;
  margin-left: auto;
  margin-right: 0.5em;
  top: 0.5em;
  position: relative;
  filter: drop-shadow(var(--shadow1));
  /* opacity: 0.7; */
  border: rgb(77, 77, 77) 0.1em solid;
}
.BalanceNum{
  font-size: 1.1em;
  padding: 0.25em 0.5em;
  margin-top: 0.3em;
  /* border-top: 1px solid var(--shade1); */
  /* background: v-bind('$store.getters.Theme.Light3'); */
  /* background: var(--shade1); */
}
/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< CARD */
</style>
